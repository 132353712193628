// Import the createTheme function from MUI
import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#3DB5E6', // Custom primary color
    },
    secondary: {
      main: '#4caf50', // Custom secondary color
    },
  },
  typography: {
    button: {
      fontWeight: 800,
      lineHeight: '1.1rem',
    },
  },
  // Add more customizations here if needed
});

// Export the theme
export default theme;
