import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container } from '@mui/material';

const buttonText = {
  de: 'Zur Stelle',
  en: 'Go to job',
};

const noJobsText = {
  de: 'Leider gibt es derzeit keine offenen Stellen!',
  en: 'Sadly, there are no open positions at the moment!',
};

const errorText = {
  de: 'Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.',
  en: 'Sadly, an error occurred. Please try again later.',
};

const locationText = {
  de: 'Ort',
  en: 'Location',
};

const postText = {
  de: 'Stelle',
  en: 'Position',
};

const FetchJobs = () => {
  const [jobs, setJobs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { language } = useParams<string>();

  const channelId = '138117_extern';
  const clientId = '6919c6f1-0fbc-4288-ab2b-b2bf0e970620';
  const encodedId = btoa(`${clientId}:`);

  const fetchData = () => {
    fetch(
      `https://api.softgarden.io/api/rest/v3/frontend/jobslist/${channelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + encodedId,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const resData = data.results.map((item: any, index: number) => {
          let translatedLocation;

          if (language === 'en') {
            translatedLocation = item.geo_name.replace(
              /München, Deutschland/g,
              'Munich, Germany'
            );
          } else {
            translatedLocation = item.geo_name;
          }

          return {
            jobName: item.externalPostingName,
            location: translatedLocation,
            link: item.applyOnlineLink,
          };
        });
        setJobs(resData);
      })
      .catch((error) => {
        console.error(error);

        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*   useEffect(() => {
    fetchData();
  }, []);
   */
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const jobRows = jobs.map((job: any, index: number) => (
    <TableRow key={index} data-testid="job-list">
      <TableCell sx={{ padding: '3px', margin: '0', width: '50%' }}>
        <b>{job.jobName}</b>
      </TableCell>
      <TableCell
        sx={{
          padding: '3px',
          margin: '0',
          width: '23%',
        }}
      >
        {job.location}
      </TableCell>
      <TableCell
        sx={{
          padding: '3px',
          margin: '0',
          textAlign: 'center',
          width: '27%',
        }}
      >
        <Button
          className="button"
          onClick={() => window.open(job.link, '_blank')}
          variant="contained"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            backgroundColor: 'frybad',
            borderRadius: '18px',
            width: 'auto', // Adjust width as needed
            maxWidth: '90%', // Ensure it doesn't exceed the cell width
            height: 'auto', // Adjust height as needed
            maxHeight: '90%', // Ensure it doesn't exceed the cell height
            textTransform: 'none', // To prevent uppercase text
            fontSize: '0.75rem',
            padding: '0.35rem 0.6rem',
            letterSpacing: '0',
          }}
        >
          {buttonText[language as 'de' | 'en']}
        </Button>
      </TableCell>
    </TableRow>
  ));

  const noJobsRow = (
    <TableRow data-testid="nojob-list">
      <TableCell
        sx={{ padding: '3px', margin: '0', textAlign: 'center' }}
        colSpan={3}
      >
        {noJobsText[language as 'en' | 'de']}
      </TableCell>
    </TableRow>
  );

  const errorRow = (
    <TableRow data-testid="error-list">
      <TableCell
        sx={{ padding: '3px', margin: '0', textAlign: 'center' }}
        colSpan={3}
      >
        {errorText[language as 'en' | 'de']}
      </TableCell>
    </TableRow>
  );

  const skeletonRows = Array.from({ length: 3 }).map((_, index) => (
    <TableRow key={index}>
      <TableCell sx={{ padding: '3px', margin: '0', width: '50%' }}>
        <Skeleton
          data-testid="skeleton"
          variant="text"
          sx={{ fontSize: '0.75rem', width: '100%' }}
        />
      </TableCell>
      <TableCell
        sx={{
          padding: '3px',
          margin: '0',
          width: '25%',
        }}
      >
        <Skeleton
          data-testid="skeleton"
          variant="text"
          sx={{ fontSize: '0.75rem', width: '100%' }}
        />
      </TableCell>
      <TableCell
        sx={{
          padding: '3px',
          margin: '0',
          width: '25%',
        }}
      >
        <Skeleton
          data-testid="skeleton"
          variant="text"
          sx={{ fontSize: '0.75rem', width: '100%' }}
        />
      </TableCell>
    </TableRow>
  ));

  const renderSwitch = () => {
    switch (true) {
      case loading:
        return skeletonRows;
      case error:
        return errorRow;
      case jobs.length === 0:
        return noJobsRow;
      default:
        return jobRows;
    }
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-8">
      <div className="table xs:w3/4 sm:w-3/4 lg:w-2/3">
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 1,
          }}
        >
          <TableContainer sx={{ minWidth: 300, maxWidth: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: '3px',
                      margin: '0',
                      fontWeight: 'bold',
                      width: '50%',
                    }}
                  >
                    {postText[language as 'en' | 'de']}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '3px',
                      margin: '0',
                      fontWeight: 'bold',
                      width: '23%',
                    }}
                  >
                    {locationText[language as 'en' | 'de']}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '3px',
                      margin: '0',
                      width: '27%',
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderSwitch()}</TableBody>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </main>
  );
};

export default FetchJobs;
